import { marked } from 'marked';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { datadogRum } from '@datadog/browser-rum';

// Initialize Datadog RUM
datadogRum.init({
    applicationId: 'e537b4ef-f764-4418-89ab-7f9eb92b72da',
    clientToken: 'pub1747a1d69dfbb0179184dc1715caf3e2',
    site: 'us5.datadoghq.com',
    service: 'csg-summarizeai',
    env: 'prod',
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

gsap.registerPlugin(ScrollTrigger);

const disableCitations = false;
const formStart = document.querySelector('#form-start');
const formRefine = document.querySelector('#form-refine');
const chatContainer = document.querySelector('#chat_container');
const sidePanel = document.querySelector('.side-panel');
const mainContent = document.querySelector('.main-content');
let playedIntroAnimation = false;
let data = null;
let docStyle = getComputedStyle(document.documentElement);

let loadInterval;

function loader(element) {
  element.textContent = '.';

  loadInterval = setInterval(() => {
    element.textContent += '.';

    if (element.textContent === '....') {
      element.textContent = '.';
    }
  }, 300)
}

function typeText(element, text) {
  let index = 0;

  let interval = setInterval(() => {
    if (index < text.length) {
      element.innerHTML += text.charAt(index);
      index++;
    } else {
      clearInterval(interval);
    }
  }, 20)
}

function generateUniqueId() {
  const timestamp = Date.now();
  const randomNumber = Math.ceil(Math.random(0, 99999));
  const hexadecimalString = randomNumber.toString(16);
  // return `id-${timestamp}-${hexadecimalString}`;
  return `id-${timestamp}`;
}

function chatStripe(isAi, value, uniqueId) {
  return (
    `
      <div class="wrapper ${isAi && 'ai'}">
        <div class="chat row">
          <div class="message col-9" id=${uniqueId}>${value}</div>
        </div>
      </div>
    `
  )
}

function gsapAnimations() {
  if (playedIntroAnimation) return;

  console.log("Playing intro animation");
  disableStartingForm();
  enableRefiningForm();

  // Animate in the refine search form on the side panel


  // Animate the background color of the main content
  var tlMainContent = gsap.timeline();
  tlMainContent.to(mainContent, { backgroundColor: docStyle.getPropertyValue("--green-dark-green"), duration: 2.0, ease: "power1.out" });
  tlMainContent.to('.search-intro', { paddingTop: 0, duration: 0.5, ease: "power1.out" }, "<");
  tlMainContent.to('.search-intro', { height: 0, duration: 0.5, ease: "power1.out" }, "<");
  tlMainContent.to('.search-intro', { opacity: 0, duration: 0.5, ease: "power1.out" }, "<");
  tlMainContent.set('.refine-search', { display: 'flex' }, ">");
  tlMainContent.from('.refine-search', { height: 0, duration: 0.5, ease: "power1.out" }, "<");
  tlMainContent.from('.refine-search', { opacity: 0, duration: 0.5, ease: "power1.out" }, "<");
  tlMainContent.to('.container-search-form', { opacity: 0, duration: 0.5, ease: "power1.out" }, "<");

  // set playedIntroAnimation to true so that the animation doesn't play again
  playedIntroAnimation = true;
}

function gsapResponseAnimations() {
var tlFooter = gsap.timeline();
    tlFooter.set('.container-search-form', { display: 'none' });
    // tlFooter.set('#footer', { minHeight: 0 });
    tlFooter.to('#footer', { opacity: 0, duration: 1.0, ease: "power1.out" }, "<");
    tlFooter.to('#footer', { height: 0, duration: 1.0, ease: "power1.out" }, "<");
    tlFooter.to('#footer', { paddingTop: 0, duration: 1.0, ease: "power1.out" }, "<");
    tlFooter.to('#footer', { paddingBottom: 0, duration: 1.0, ease: "power1.out" }, "<");
    tlFooter.to('#footer', { minHeight: 0, duration: 1.0, ease: "power1.out" }, "<");
    // tlFooter.set('#footer', { display: 'none' });  
}

const handleSubmit = async (e) => {
  e.preventDefault();
  if (playedIntroAnimation) {
    data = new FormData(formRefine);
  } else {
    data = new FormData(formStart);
  }
  gsapAnimations();



  //user's chatstripes
  chatContainer.innerHTML += chatStripe(false, data.get('prompt'));

  // formStart.reset();

  //bot's chatstripe
  const uniqueId = generateUniqueId();
  chatContainer.innerHTML += chatStripe(true, " ", uniqueId);

  chatContainer.scrollTop = chatContainer.scrollHeight;

  const messageDiv = document.getElementById(uniqueId);

  loader(messageDiv)

  const apiUrl = 'https://api.yamamotoqa.com/pgap/collection';

  const requestData = {
    "slug": "csg-ai",
    "chats": [
      {
        "message": data.get('prompt'),
        "userFlag": true
      }
    ],
    "llmType": "anthropic-claude35",
    "core": disableCitations
  };

  //Fetch data from server for bot response
  const response = await fetch(apiUrl, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'content-type': 'application/json'
    },
    body: JSON.stringify(requestData),
  })

  clearInterval(loadInterval);
  messageDiv.innerHTML = '';

  if (response.ok) {
    const data = await response.json();
    // console.log(data);
    let parsedData = data.response;

    // trim and markdown the response
    parsedData = marked(parsedData.trim());

    // If there are citations, display them
    if (data.citations.length > 0) {
      console.log("Collecting citations:");
      messageDiv.innerHTML = parsedData;
      messageDiv.innerHTML += '<div class="sources-header">SOURCES</div>';
      let citations = data.citations;
      let parsedCitations = '<ul class="sources-list">';
      // if citation.filename is a duplicate, don't display it
      let seen = new Set();
      citations = citations.filter(citation => {
        if (seen.has(citation.filename)) {
          return false;
        } else {
          seen.add(citation.filename);
          let cleanCitation = citation.filename;
          console.log(cleanCitation);
          cleanCitation = cleanCitation.split("/").pop();
          if (cleanCitation.includes("|")) {
            cleanCitation = cleanCitation.replaceAll("|", "/");
            cleanCitation = cleanCitation.replace(".pdf", "/");
            cleanCitation = cleanCitation.replace("csgi.com", "https://www.csgi.com");
            // turn the citation into a clickable link
            cleanCitation = `<a href="${cleanCitation}" target="_blank">${cleanCitation}</a>`;
          }
          parsedCitations += `<li>${cleanCitation}</li>`;
          return true;
        }
      });
      parsedCitations += '</ul>';
      messageDiv.innerHTML += parsedCitations;
    } else {
      typeText(messageDiv, parsedData);
    }
    gsapResponseAnimations();
  } else {
    const err = await response.text();
    console.log(err);
    messageDiv.innerHTML = "Hmmm, I'm not sure. Could you try rephrasing that?";
  }

}

function enableRefiningForm() {
  console.log("Enabling refining form");

  document.getElementById("question-prompt-refine").textContent = document.getElementById("question-prompt").value;

  formRefine.addEventListener('submit', handleSubmit);
  formRefine.addEventListener('keyup', (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  })
}
function enableStartingForm() {
  console.log("Enabling starting form");
  formStart.addEventListener('submit', handleSubmit);
  formStart.addEventListener('keyup', (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  })
}
function disableStartingForm() {
  console.log("Disabling starting form");
  formStart.removeEventListener('submit', handleSubmit);
  formStart.removeEventListener('keyup', (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  })
}



function init() {
  enableStartingForm();
  // gsap sticky side panel
  let stickySidePanel = gsap.timeline({
    scrollTrigger: {
      trigger: sidePanel,
      pin: true,
      start: "top top",
      end: "999999",
      scrub: 1,
      anticipatePin: 1,
      markers: false
    }
  });
  document.getElementById('q0btn').addEventListener('click', function () {
    var textContent = this.querySelector('.question-text').innerText;
    document.getElementById('question-prompt').value = textContent;
    document.getElementById('question-prompt-refine').value = textContent;
  });
  document.getElementById('q1btn').addEventListener('click', function () {
    var textContent = this.querySelector('.question-text').innerText;
    document.getElementById('question-prompt').value = textContent;
    document.getElementById('question-prompt-refine').value = textContent;
  });
  document.getElementById('q2btn').addEventListener('click', function () {
    var textContent = this.querySelector('.question-text').innerText;
    document.getElementById('question-prompt').value = textContent;
    document.getElementById('question-prompt-refine').value = textContent;
  });
}
init();